.box-contact {
	background-color: rgba($black-75, .1);
	margin: 30px -20px 0;
	padding: 30px;
	text-align: center;
	position: relative;
	border-radius: $border-radius;

	@media ($min-lg) {
	margin: 10px 0 0;
	padding: 0;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-image: url('../img/bg-contact.png');
	background-position: bottom right;
	background-repeat: no-repeat;
	}

	&__content {
		@media ($min-lg) {
			max-width: 488px;
		}
	}

	&__description {
		font-size: rem(30);
		line-height: rem(36);
		font-weight: 300;
		margin: 0;
	}

	&__button {
		margin-block-start: 20px;

		@media ($min-lg) {
			margin-block-start: 25px;
		}
	}

	&__img {
		display: none;

		@media ($min-lg) {
			display: block;
			margin: -20px 0;
		}
	}
}