.client-services {
	&__container {
		display: flex;
		flex-direction: column;
		padding: 32px 20px;
		align-items: center;

		@media ($min-lg) {
			@include container-desktop();
			padding-block: 64px;
			align-items: flex-end;
		}
	}

	&__cards {
		display: grid;
		gap: 20px;
		padding-block: 32px;

		@media ($min-md) {
			grid-template-columns: 1fr 1fr;
		}

		@media ($min-lg) {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}