.hero {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	min-height: calc(100vh - $header-mobile);
	padding: 20px;

	@media ($min-md) {
		min-height: calc(100vh - $header-tablet);
		justify-content: center;
	}

	@media ($min-xl) {
		min-height: 100vh;
	}

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50vh;
		background: linear-gradient(180deg, rgba($black-85, 0) 0%, rgba($black-85, 0.488095) 32.4%, $black-85 87.5%);

		@media ($min-md) {
			height: 100vh;
			background: linear-gradient(90deg, rgb($black, 50%), transparent);
		}

		@media ($min-lg) {
			display: none;
		}
	}

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		text-align: center;
		z-index: 1;
		gap: 6px;

		@media ($min-md) {
			@include container-desktop();
			align-items: flex-start;
			text-align: start;
			padding-left: 26px;
			width: 100%;
			gap: 20px;
		}
	}

	&__title,
	&__description {
		max-width: 285px;

		@media ($min-sm) {
			max-width: 360px;
		}
	}

	&__title {
		font-weight: 400;
		font-size: rem(40);
		line-height: 100%;
		letter-spacing: -0.05em;
		color: $white;
		margin: 0;

		@media ($min-sm) {
			font-size: rem(44);
		}

		@media ($min-md) {
			max-width: 496px;
			font-weight: 300;
			font-size: 75px;
			line-height: 100%;
			letter-spacing: -0.05em;
			color: $darkgrey;
		}

		@media ($min-lg) {
			font-size: 95px;
			max-width: 628px;
		}

		@media ($min-xl) {
			font-size: 115px;
			max-width: 762px;
		}

		@media ($min-hd) {
			font-size: 135px;
			max-width: 906px;
		}
	}

	&__description {
		font-size: rem(14);
		line-height: 140%;
		letter-spacing: -0.05em;
		margin: 0;
		color: $lightgrey-50;

		@media ($min-sm) {
			font-size: rem(16);
		}

		@media ($min-md) {
			max-width: 400px;
			font-size: rem(22);
			font-weight: 400;
			line-height: 120%;
			letter-spacing: normal;
			color: $lightgrey-50;
		}

		@media ($min-lg) {
			max-width: 528px;
			font-size: rem(24);
			font-weight: 300;
		}

		@media ($min-xl) {
			max-width: 600px;
		}

		@media ($min-hd) {
			max-width: 630px;
			font-size: rem(30);
		}
	}

	&__background {
		position: absolute;
		height: 100%;
		min-width: 100%;
		width: 100%;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		object-fit: cover;

		@media ($min-md) {
			object-position: 65% center;
		}

		@media ($min-xl) {
			object-position: top;
		}
	}

	&.hero--light {
		background-color: $grey-10;

		&:after {
			@media ($min-md) {
				background: linear-gradient(90deg, rgb($white, 50%), transparent);
			}
		}

		& .hero__title,
		& .hero__description {
			@media ($min-md) {
				color: $black;
			}
		}
	}
}