.accordion {
	&__header,
	&__content {
		position: relative;
		border: 1px solid $grey-50;
		padding-block: rem(20);
		padding-inline: rem(14) calc(rem(14) + 36px);
		background-color: $white;

		font-size: rem(15);
		color: $black-85;

		@media ($min-lg) {
			font-size: rem(18);
			padding-inline: rem(32) calc(rem(32) + 70px);
		}
	}

	&__header {
		list-style: none;
		cursor: pointer;
		border-radius: 5px;
		font-weight: 700;
		line-height: 1.1;
		overflow: hidden;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 36px;
			background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNyA3TDEzIDEiIHN0cm9rZT0iIzVFMDEwRCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
			background-position: center;
			background-repeat: no-repeat;
			background-color: $lightgrey-50;
			border-left: 1px solid $grey-50;

			@media ($min-lg) {
				width: 70px;
				background-size: 18px;
			}
		}
	}

	&__content {
		padding-top: 0;
		border-top: 0;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;

		line-height: 1.6;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			width: 36px;
			background-color: $lightgrey-50;
			border-left: 1px solid $grey-50;

			@media ($min-lg) {
				width: 70px;
			}
		}
	}

	&[open] &__header {
		border-bottom: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;

		&:after {
			transform: rotate(180deg);
			border-left: 0;
			border-right: 1px solid $grey-50;
		}
	}

	& .document {
		margin-top: 15px;

		&__wrapper {
			display: flex;
			flex-direction: column;

			@media ($min-sm) {
				display: grid;
			}

			@media ($min-lg) {
				display: flex;
				flex-direction: row;
			}
		}

		&__button {
			@media ($max-sm) {
				max-width: 100px;
			}
		}
	}
}

@keyframes sweep {
	0% {
		opacity: 0;
		transform: translateX(-10px)
	}

	100% {
		opacity: 1;
		transform: translateX(0)
	}
}