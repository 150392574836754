.hyperlink-menu {
  display: none;

  @media ($min-xl) {
    display: none;
    position: sticky;
    background-color: $darksilver;
    padding: 23px;
    // Calculo = tamanho do padding + tamanho do botão (rem)
    transform: translate(0, calc(-46px - rem(30)));
    top: calc($header-desktop + 45px + rem(30));
    // margin para não manter o espaço em branco quando receber o translate.
    margin-bottom: calc(-46px - rem(30));
    z-index: $hyperlink-menu-index;

    &.hyperlink-menu--loaded {
      display: block;
    }
  }
  
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  &__item {
    font-weight: 700;
    font-size: rem(10);
    line-height: rem(30);
    text-transform: uppercase;
    text-align: center;
    color: $black-75;
    width: 260px;
    background-color: $white;
    border-radius: 20px;
    padding: 0 12px;

    /*! autoprefixer: off */
    -webkit-box-orient: vertical; 
    /* autoprefixer: on */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;

    transition: background-color 0.3s;

    &--active {
      background-color: $grey-60;
    }
  }
}

.hyperlink-section-spacing {
  @media ($min-xl) {
    border-top: calc($header-desktop + 46px + rem(30) + 46px + rem(30)) solid transparent;
    // O primeiro elemento tem que dar duas vezes o tamanho negativo do hyperlink-menu no margin,
    // pois o hyperlink-menu tem um margin bottom negativo, então é o tamanho dele + esse margin.
    margin-top: calc(-1 * $header-desktop - 46px - rem(30) - 46px - rem(30));
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;

    & + & {
      // Tamanho do header + padding do hyperlink-menu + tamanho do botão
      border-top: calc($header-desktop + 46px + rem(30)) solid transparent;
      margin-top: calc(-1 * $header-desktop - 46px - rem(30));
      -webkit-background-clip: padding-box;
      -moz-background-clip: padding;
      background-clip: padding-box;
    }
  }
 
}