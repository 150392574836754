// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.header {
  width: 100%;
  background-color: $lightgrey-95;
  padding: 12px 20px 10px;
  position: fixed;
  z-index: $header-index;

  @media ($min-xl) {
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    border-bottom: 1px solid transparent;
    padding: 0 20px;
    transition: all 0.3s;

    &:hover {
      background-color: $lightgrey-50;
      border-color: $lightgrey;
    }

    &.header--sticky {
      background-color: $lightgrey-50;
      border-color: $lightgrey;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ($min-xl) {
      @include container-desktop;
    }
  }

  &__logo {
    font-size: 0;
    line-height: 0;

    @media ($min-xl) {
      margin-right: 72px;
    }

    img {
      width: 92px;

      @media ($min-md) {
        width: 136px;
      }

      @media ($min-xl) {
        width: 170px;
      }
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 565px;
    position: absolute;
    top: 100%;
    left: -110%;
    transition: all 0.4s;
    height: calc(100vh - $header-mobile);

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      width: calc(100vw - 565px);
      height: 100%;
      z-index: 0;
      background: rgba($black, .8);
      backdrop-filter: blur(3px);

      @media ($min-xl) {
        content: none;
      }
    }

    @media ($min-md) {
      height: calc(100vh - $header-tablet);
    }

    @media ($min-xl) {
      position: static;
      flex-direction: row-reverse;
      align-items: center;
      transition: none;
      height: auto;
      max-width: none;
    }
  }

  &__buttons-container { 
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 19px 18px;
    padding: 18px 20px 26px;
    background-color: $grey-10;

    @media ($min-xl) {
      display: flex;
      background-color: transparent;
      align-items: center;
      padding: 0;
      margin-left: 54px;
    }

    &:before {
      content: "";
      width: 100%;
      height: 8px;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.5;

      @media($min-xl) {
        content: none;
      }
    }
  }

  &__button-login {
    background-color: rgba($white, 0.4);
    font-weight: 400;
  }

  &__search-mobile {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    background-color: transparent;
    border: 1px solid $primary;
    border-radius: 20px;
    padding: 11px 20px;
    grid-column: 1 / 3;

    @media ($min-xl) {
      display: none;
    }

    & > button {
      display: inline-flex;
      background: transparent;
      border: 0;

      img {
        width: 19px;
      }
    }

    input[type="text"] {
      width: 100%;
      border: 0;
      outline: 0;
      color: $black;
      background-color: transparent;
      font-size: rem(13);
      line-height: rem(17);

      &::placeholder {
        color: $black;
        text-transform: uppercase;
      }
    }
  }
}

// Estilo do icone de hamburguer e abertura do menu mobile
.hamburger-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 22px;
  height: 18px;
  cursor: pointer;

  @media ($min-xl) {
    display: none;
  }

  &__lines,
  &__lines:before,
  &__lines:after {
    display: block;
    width: 22px;
    height: 1.8px;
    background-color: $primary;
    border-radius: 10px;
    transition: .4s ease-in-out;
  }

  &__lines {
    position: relative;
  }

  &__lines:before {
    position: absolute;
    content: '';
    top: -8px;
    right: 0;
    width: 75%;
  }

  &__lines:after {
    position: absolute;
    content: '';
    top: 8px;
  }

  &__checkbox {
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;

    @media ($min-xl) {
      display: none;
    }
  }

  &__checkbox:checked ~ & &__lines {
    transform: rotatez(-45deg);
  }

  &__checkbox:checked ~ & &__lines:before {
    transform: rotatez(90deg) translate(8px, 0px);
    width: 100%;
  }

  &__checkbox:checked ~ & &__lines:after {
    transform: translate(0, -8px) rotatez(0deg);
  }

  &__checkbox:checked ~ .header__nav {
    left: 0;
  }
}

.nav-menu {
  background-color: $grey-10;
  flex: 1;
  overflow-y: auto;

  @media ($min-xl) {
    background-color: transparent;
  }

  > ul {
    border-top: 1px solid $grey-20;
    padding: 0;
    margin: 0;
    list-style: none;

    @media ($min-xl) {
      border-top: none;
      display: flex;
      justify-content: space-between;
    }
  }

  // Apenas desktop
  // Hover do item do menu e mudanças na lista e link
  &__dropdown:hover {
    @media ($min-xl) {
      & > .nav-menu__item {
        color: $primary-75;

        &:before {
          opacity: 1;
        }
      }
      
      & > .nav-menu__submenu {
        visibility: visible;
        opacity: 1;
      }      
    }
  }
 
  &__item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: rem(16);
    line-height: rem(20);
    font-weight: 700;
    color: $black;
    background-color: $lightgrey-50;
    padding: 12px 20px;
    border-bottom: 1px solid $grey-20;

    @media ($min-xl) {
      position: relative;
      display: inline-block;
      padding: 0;
      border-bottom: none;
      background-color: transparent;
      white-space: nowrap;
      line-height: 90px;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    // Desktop
    @media ($min-xl) {
      &:before {
        content: "";
        width: 100%;
        height: 8px;
        position: absolute;
        bottom: 0;
        background-color: $primary-75;
        border-radius: 4px 4px 0px 0px;
        opacity: 0;
        transition: opacity 0.3s;
      }
    }

    // Mobile
    &--active {

      & ~ .nav-menu__submenu {
        max-height: 1000px;
      }

      & > .accordion-arrow {
        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__submenu {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease;
    padding: 0;
    margin: 0;
    list-style: none;

    @media ($min-xl) {
      visibility: hidden;
      opacity: 0;
      max-height: initial;
      position: absolute;
      min-width: 360px;
      padding: 4px 36px 4px 20px;
      background-color: $lightgrey-50;
      border-radius: 0px 0px 10px 10px;
      transition: all 0.3s;
      box-shadow: $shadow-drop;
    }
  }

  &__submenu-item {
    background-color: $lightgrey-70;
    border-bottom: 1px solid $grey-20;
    
    @media ($min-xl) {
      background-color: transparent;

      &:last-child {
        border-bottom: none;
      }
    }

    a {
      display: block;
      font-size: rem(17);
      line-height: rem(20);
      color: $black;
      padding: 12px 34px;
  
      @media ($min-xl) {
        padding: 18px 0;
      }

      &:hover {
        text-decoration: none;

        @media ($min-xl) {
          color: $primary-75;
        }
      }
    }
  }
}

.accordion-arrow {
  width: 4px;
  height: 20px;
  display: inline-block;
  position: relative;
  margin: 0 16px;

  @media ($min-xl) {
    display: none;
  }

  &:before,
  &:after {
    top: 8px;
    position: absolute;
    width: 9px;
    height: 2px;
    background-color: $primary;
    display: inline-block;
    transition: all .2s ease;
    border-radius: 25px;
  }

  &:after {
    content: "";
    right: 0;
    transform: rotate(45deg);
  }

  &:before {
    content: "";
    left: 0;
    transform: rotate(-45deg);
  }
}

// HEADER THEMES
.header.header--dark {
  @media ($min-xl) {
    & .header__logo {
      filter: brightness(0) invert(1);
    }

    & .search-icon {
      img {
        filter: brightness(0) invert(1);
      }

      &:hover,
      &:focus-visible,
      &:active {
        background-color: $white;
        border-radius: 50%;
      }

      &:hover img,
      &:focus-visible img {
        filter: none;
      }

      &:focus-visible {
        outline: $focus;
      }

      &:active img {
        filter: brightness(1.5);
      }
    }

    & .nav-menu__item,
    & .nav-menu__dropdown:hover>.nav-menu__item {
      color: $white;
    }
  
    & .header__button-login {
      color: $white;
      background-color: transparent;
      border-color: $white;
      font-weight: 400;

      &:hover {
        color: $white;
        background-color: rgba($black-75, 0.6);
        border-color: $white;
      }

      &:focus-visible,
      &:active {
        color: $white;
        background-color: $black-75;
        border-color: $white;
        outline: $focus;
      }

      &:active {
        outline: none;
      }
    }

    &:hover.header--dark,
    &.header--sticky.header--dark {  
      background-color: $black-75;
      border-color: $black-50;
    }
  }
}

// Para o Lumis
#LumisAdminWorkPaneBodyBg .header {
  position: inherit;
}