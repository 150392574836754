// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Kitty Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin container-desktop() {
  max-width: $max-width;
  margin: 0 auto;
}

/// Cria um botão baseado no tema.
/// @param {*} $button-theme [light|dark|grey] 
/// @param {*} $size [medium|big] 
@mixin btn($size: medium) {
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s;

  @include on-event(true) {
    text-decoration: none;
  }

  // SIZE
  @if $size == big {
    font-weight: 700;
    font-size: rem(16);
    line-height: rem(28);
    padding: 16px 20px;
    border-radius: 30px;
    min-width: 184px;
  } @else {
    font-weight: 700;
    font-size: rem(13);
    line-height: rem(20);
    padding: 10px 16px;
    border-radius: 20px;
    min-width: 135px;
  }
}