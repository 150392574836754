.call-to-action {
  background-color: $black-50;
  position: relative;

  &__container {
    position: relative;
    z-index: 1;
    overflow: hidden;

    @media($min-xl) {
      @include container-desktop();
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 12px;
    margin: 50px 20px 0;

    @media ($min-md) {
      gap: 15px;
      margin-top: 80px;
    }
  }

  &__title {
    margin: 0;
    font-weight: 400;
    font-size: rem(45);
    line-height: 100%;
    letter-spacing: -0.05em;
    color: $lightgrey-50;

    @media ($min-md) {
      max-width: 300px;
    }
  }

  &__description {
    margin: 0;
    font-weight: 400;
    font-size: rem(20);
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.05em;    
    color: $lightgrey-50;

    @media ($min-md) {
      max-width: 300px;
    }
  }

  &__button {
    width: 198px;
    margin-top: 8px;

    @media ($min-md) {
      margin-top: 15px;
    }
  }

  &__emphasis__mobile {
    margin: 200px 0 0;
    -webkit-text-stroke: 1px rgba($white ,.35);
    font-size: 62vw;
    line-height: 100%;
    text-transform: uppercase;
    font-weight: 700;
    color: transparent;

    @media($min-sm) {
      display: none;
    }
  }

  &__emphasis {
    display: none;
    margin: 200px 0 0;
    -webkit-text-stroke: 1px rgba($white ,.35);
    line-height: 100%;
    text-transform: uppercase;
    font-weight: 700;
    color: transparent;
    white-space: nowrap;

    @media ($min-sm) {
      display: block;
      transform: none;
      font-size: 14vw;
      line-height: 100%;
      text-align: center;
    }

    @media ($min-md) {
      margin: 28px 0 0;
    }

    @media ($min-hd) {
      font-size: 200px;
    }
  }

  &__background {
    position: absolute;
    height: 100%;
    min-width: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
  }

  &.call-to-action--job-vacancy {
    min-height: 672px;

    @media ($min-md) {
      min-height: 486px;
    }
  }
}