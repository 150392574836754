// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

//GOTHAM
@media all and (min-resolution:.001dpcm) {
  @font-face {
    font-family: 'Gotham';
    src: url(../fonts/GothamLight.woff2);
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(../fonts/GothamBook.woff2);
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(../fonts/GothamMedium.woff2);
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(../fonts/GothamBold.woff2);
    font-weight: 700;
    font-display: swap;
  }
}