.complaint {
	background-color: $lightgrey-50;
	padding: rem(20) rem(20) rem(80);

	@media ($min-xl) {
		padding: rem(20) rem(20) rem(80);
	}

	.complaint-form__paragraph {
		font-size: rem(16);
		margin-block-start: rem(16)
	}
}

#dados_pessoais {
	visibility: visible;
	opacity: 1;
	height: auto;
	position: static;
	pointer-events: all;
	transition: visibility .2s ease, opacity .2s ease;

	&.hidden {
		visibility: hidden;
		opacity: 0;
		height: 0;
		position: absolute;
		pointer-events: none;
	}
}