/**
 * Basic typography style for copy text
 */
h1,
.h1 {
  font-weight: 400;
  font-size: rem(41);
  line-height: 100%;
  letter-spacing: -0.05em;

  @media ($min-xl) {
    font-weight: 300;
    font-size: rem(135);
  }
}

h2,
.h2 {
  font-weight: 700;
  font-size: rem(35);
  line-height: 100%;

  @media ($min-xl) {
    font-size: rem(50);
  }
}

h3,
.h3 {
  font-weight: 400;
  font-size: rem(22);
  line-height: 160%;

  @media($min-xl) {
    font-size: rem(25);
  }
}

p {
  font-weight: 400;
  font-size: rem(18);
  line-height: 160%;
}