.investments-section {
  background-color: $lightgrey-50;
  padding: rem(18) rem(20);

  @media ($min-xl) {
    padding: rem(32) rem(20) rem(52);
  }

  &__wrapper {
    @include container-desktop();
  }

  &__select {
    appearance: none;
    width: 100%;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNyA3TDEzIDEiIHN0cm9rZT0iIzVFMDEwRCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-color: $white;

    border: 1px solid $grey-50;
    border-radius: 20px;
    padding: 12px 36px 12px 20px;
    margin-bottom: rem(18);

    font-size: rem(10);
    line-height: 1.3;
    text-transform: uppercase;
    color: $black-85;

    @media ($min-lg) {
      font-size: rem(13);
    }

    @media ($min-xl) {
      max-width: rem(320);
      margin-bottom: rem(40);
    }
  }
}

.investments-table {
  width: 100%;

  @media ($min-xl) {
    border-spacing: 0 15px;
  }

  &__header {
    display: none;

    @media ($min-xl) {
      display: table-row;
    }

    & th {
      font-weight: 700;
      font-size: rem(12);
      line-height: 1.6;
      color: $blue-grey;
      text-align: left;

      @media ($min-xl) {
        padding-inline: 12px;
      }
    }

    & th:first-child {
      @media ($min-xl) {
        padding-inline: 20px 12px;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: rem(12);
  
    background-color: $white;
    padding: rem(8) rem(10) rem(14);
    border: 1px solid $grey-50;
    border-radius: 5px;

    @media ($min-md) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media ($min-xl) {
      display: table-row;
    }

    & + tr {
      margin-top: rem(10);
    }

    & td:before {
      content: attr(data-thead);
      display: block;
      font-weight: 700;
      font-size: rem(9);
      line-height: 1.6;
      color: $blue-grey;
      text-transform: uppercase;

      @media ($min-xl) {
        display: none;
      }
    }

    & td:first-child,
    & td:last-child {
      grid-column: 1 / 3;

      @media ($min-md) {
        grid-column: 1 / 5;
      }
    }

    & td {
      font-size: rem(14);
      line-height: 1.6;
      color: $black-85;

      @media ($min-lg) {
        font-size: rem(18);
      }

      @media ($min-xl) {
        border-top: 1px solid $grey-50;
        border-bottom: 1px solid $grey-50;
        padding-inline: 12px;
      }
    }

    @media ($min-xl) {
      & td:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-left: 1px solid $grey-50;
        padding-block: rem(16);
        padding-inline: 20px 12px;
        width: 30%;
      }

      & td:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-right: 1px solid $grey-50;
        padding-block: rem(16);
      }
    }

    &--inactive {
      display: none;
    }
  }

  &__title strong,
  &__title span {
    display: block;
  }

  &__title strong {
    font-weight: 700;
    font-size: rem(14);
    line-height: 1.2;
    color: $black-75;

    @media ($min-lg) {
      font-size: rem(16);
      line-height: 1;
    }
  }

  &__title span {
    font-weight: 500;
    font-size: rem(9);
    line-height: 1.1;
    color: $darkgrey;

    @media ($min-lg) {
      font-size: rem(12);
    }
  }

  &__highlight {
    @media ($min-xl) {
      display: inline-block;
      background-color: $lightgrey-65;
      padding: 0 8px;
    }
  }

  &__button {
    text-align: center;

    & a,
    & button {
      white-space: nowrap;
    }
  }
}

.investments-know-more {
  padding: rem(28) rem(20);

  @media ($min-lg) {
    padding: rem(80) rem(20);
  }
  
  &__wrapper {
    @include container-desktop;
  }

  &__title {
    font-weight: 400;
    font-size: rem(22);
    line-height: 1.3;
    color: $black;
    text-align: center;

    @media ($min-lg) {
      font-size: rem(25);
      line-height: 1.6;
    }
  }

  &__hr {
    width: rem(58);
    height: 5px;
    border: 0;
    border-radius: 2px;
    margin: 10px auto 26px;
    background-color: $darkgrey;

    @media ($min-lg) {
      margin: 15px auto 36px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: rem(15);
  }
}