/**************************\
  Basic Modal Styles
\**************************/
.modal {
  position: relative;
  z-index: $modal-index;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($black ,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__container {
    background-color: $darkgrey;
    padding: 50px 36px;
    width: 100%;
    max-width: 700px;
    max-height: 100vh;
    border-radius: 5px;
    overflow-y: auto;
    box-sizing: border-box;
    box-shadow: $shadow-drop;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin: 0 0 12px;
    font-weight: 300;
    font-size: rem(30);
    line-height: rem(30);
    color: $white;
  }

  &__close {
    background: transparent;
    border: 0;
    position: relative;
    transform: translate(22px, -44px);
    color: $white;

    &:before {
      content: "\2715";
    }
  }

  & .search-modal-form {
    display: flex;
    align-items: center;
    background-color: $white;
    border-radius: 30px;
    padding: 10px 24px;

    // &:focus-within {
    //   outline: $focus;
    // }

    & input[type="text"] {
      width: 100%;
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(28);
      color: $black-75;
      border: 0;

      &:focus,
      &:active {
        outline: none;
      }

      &:placeholder {
        color: $black-75;
      }
    }

    &__button {
      border: 0;
      background-color: transparent;
      width: 40px;
      height: 40px;
      padding: 10px;
      transition: all 0.3s;
      border-radius: 50%;

      &:hover img,
      &:focus-visible img,
      &:active img {
        filter: brightness(0) invert(1);
      }
      
      &:hover,
      &:focus-visible {
        background-color: $primary;
      }

      &:focus-visible {
        outline: $focus;
      }

      &:active {
        background-color: $primary-50;
      }

      img {
        filter: brightness(1.5)
      } 
    }
  }
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;

  &.is-open {
    display: block;
  }

  &[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  &[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }

  &[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  &[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }

  & .modal__container,
  & .modal__overlay {
    will-change: transform;
  }
}