.default-form {
	@include container-desktop();

	max-width: 900px;

	&__fieldset {
		display: flex;
		flex-direction: column;
		gap: rem(10);
		border: 0;

		@media ($min-lg) {
			gap: rem(15);
		}

		&+fieldset {
			margin-top: rem(30);

			@media ($min-lg) {
				margin-top: rem(80);
			}
		}
	}

	&__legend {
		font-size: rem(22);
		line-height: 1.3;
		color: $black-85;
		margin-bottom: rem(15);

		@media ($min-lg) {
			font-size: rem(25);
			line-height: 1.6;
		}
	}

	&__select {
		background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAyMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMS41TDExIDEwLjVMMjAgMS41IiBzdHJva2U9IiM4MTg4OEMiIHN0cm9rZS13aWR0aD0iMi41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
		background-position: right 26px center;
		background-repeat: no-repeat;
		background-color: $white;
		background-size: 18px;

		width: 100%;
		padding-block: rem(14);
		padding-inline: rem(20) calc(rem(20) + 32px);
		border: 1px solid $grey-50;
		border-radius: 10px;

		font-weight: 500;
		font-size: rem(14);
		line-height: rem(22);
		color: $black-85;

		appearance: none;

		@media ($min-lg) {
			padding-block: rem(22);

			font-size: rem(18);
			line-height: rem(24);
		}
	}

	&__input-container {
		display: flex;
		align-items: center;
		position: relative;

		min-height: rem(50);

		background: $white;
		border: 1px solid $grey-50;
		border-radius: 10px;
		padding: rem(8) rem(20);
		cursor: text;

		@media ($min-lg) {
			min-height: rem(70);
			padding: rem(16) rem(20);
		}

		&:focus-within {
			outline: 2px solid $black-85;
		}
	}

	&__label {
		font-weight: 500;
		font-size: rem(14);
		line-height: 1;
		color: $black-85;

		transition: all 0.3s;

		@media ($min-lg) {
			font-size: rem(18);
		}

		&-small {
			font-size: rem(16);
			font-weight: 400;
			transition: all .3s ease;
		}
	}

	&__input {
		position: absolute;
		left: rem(20);
		right: rem(20);
		bottom: rem(8);

		border: 0;

		font-size: rem(14);
		line-height: 1;
		color: $black-85;

		@media ($min-lg) {
			bottom: rem(16);
			font-size: rem(18);
		}

		&:focus {
			outline: 0;
		}

		&:placeholder-shown {
			opacity: 0;
		}

		&:not(:placeholder-shown) {
			opacity: 1;
		}

		&:not(:placeholder-shown)~.default-form__label,
		&:focus~.default-form__label {
			transform: translate(0, rem(-12));
			position: relative;
			font-size: rem(12);

			@media ($min-lg) {
				font-size: rem(14);
				transform: translate(0, rem(-14));
			}

			.default-form__label-small {
				font-size: rem(12);
			}
		}
	}

	&__textarea-container {
		display: block;
		position: relative;
		min-height: rem(148);

		background: $white;
		border: 1px solid $grey-50;
		border-radius: 10px;
		padding: rem(15) rem(20);

		@media ($min-lg) {
			min-height: rem(278);
			padding: rem(20);
		}

		&:focus-within {
			outline: 2px solid $black-85;
		}
	}

	&__textarea {
		position: absolute;
		left: rem(20);
		right: rem(20);
		top: rem(30);
		bottom: rem(15);

		border: 0;
		resize: none;

		font-size: rem(14);
		line-height: 1;
		color: $black-85;

		@media ($min-lg) {
			top: rem(40);
			bottom: rem(20);

			font-size: rem(18);
		}

		&:focus {
			outline: 0;
		}

		&:placeholder-shown {
			opacity: 0;
		}

		&:not(:placeholder-shown) {
			opacity: 1;
		}

		&:not(:placeholder-shown)~.default-form__label,
		&:focus~.default-form__label {
			position: absolute;
			top: rem(10);
			left: rem(20);
			font-size: rem(12);

			.default-form__label-small{
				visibility: hidden;
				opacity: 0;
			}

			@media ($min-lg) {
				top: rem(16);
				font-size: rem(14);
			}
		}
	}

	& .has-danger {

		& label,
		& select {
			border-color: $primary-40;

			&:focus-within {
				outline-color: $primary-40;
			}
		}

		& input,
		& textarea {
			color: $primary-40;
		}

		& .pristine-error {
			font-size: rem(12);
			line-height: 1.2;
			color: $primary-40;
			margin-top: rem(6);
			margin-left: rem(4);

			@media ($min-lg) {
				font-size: rem(16);
				margin-top: rem(8);
				margin-left: rem(8);
			}
		}
	}

	&__buttons-container {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		margin-top: rem(16);
		gap: 20px;
	}

	&__buttons-show-container {
		display: flex;
		flex-direction: column;
		gap: rem(12);
		flex-wrap: wrap;

		@media ($min-sm) {
			flex-direction: row;
		}

		& ~ .default-form__fieldset {
			margin-block-start: rem(40);
		}
	}

	&__cancel-button {
		min-width: 115px;

		@media (min-lg) {
			min-width: 135px;
		}
	}

	&__submit-button {
		@media (min-lg) {
			min-width: 135px;
		}
	}

	&__show-button {
		&.active {
			@extend .btn-blue-grey;
		}
	}

	&__radio-container {
		display: flex;
		gap: rem(20);

		& label {
			width: 100%;
			cursor: pointer;
			font-weight: 500;
			font-size: rem(14);
			line-height: 1.5;
			color: $black-85;
			padding-inline: 0.75rem;

			@media ($min-md) {
				padding-inline: 1.25rem;
			}

			@media ($min-lg) {
				font-size: rem(18);
			}
		}

		& input[type="radio"] {
			margin-right: rem(12);
			width: 1.375rem;
			height: 1.375rem;
			appearance: none;
			background-color: white;
			border-radius: 50%;
			border: .125rem solid $darkgrey;
			transform: translateY(-0.075em);
			display: grid;
			place-content: center;
			flex-shrink: 0;

			&:checked::before {
				content: "";
				width: .625rem;
				height: .625rem;
				border-radius: 50%;
				background-color:  $darkgrey;
			}

		}		
	}

	&__checkbox-container {
		display: flex;
		flex-direction: column;
		gap: rem(24);
		margin-bottom: rem(28);

		& label {
			display: flex;
			font-weight: 500;
			font-size: rem(14);
			line-height: 1;
			color: $black-85;

			@media ($min-lg) {
				font-size: rem(18);
			}
		}

		& input[type="checkbox"] {
			margin-right: rem(12);
			width: 1.125rem;
			height: 1.125rem;
			appearance: none;
			background-color: $white;
			color: $darkgrey;
			border: .125rem solid $darkgrey;
			border-radius: 0.15em;
			transform: translateY(-0.075em);
			display: grid;
			place-content: center;
			flex-shrink: 0;

			&:checked::before {
				content: "";
				width: 0.65em;
				height: 0.65em;
				clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
				transform-origin: bottom left;
				transition: 120ms transform ease-in-out;
				background-color: $darkgrey;
			}	

		}
	}
}

.default-form-modal {
  &__container {
    background-color: $white;
    text-align: center;
  }

  &__overlay {
    padding: 32px;
  }

  &__header {
    justify-content: center;
  }

  &__title {
    font-weight: 700;
    font-size: rem(32);
    line-height: 1;
    color: $black-85;
    justify-self: center;

    @media ($min-lg) {
      font-size: rem(50);
    }

    &--error {
      color: $primary-40;
    }
  }

  &__description {
    font-size: rem(24);
    line-height: 1;
    color: $black-85;
    margin-top: rem(8);

    @media ($min-lg) {
      font-size: rem(35);
    }
  }

  &__button {
    margin-top: rem(30);
  }
}