.home-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  background-color: $grey-10;
  min-height: calc(100vh - $header-mobile);
  padding: 20px;

  @media ($min-md) {
    min-height: calc(100vh - $header-tablet);
    justify-content: center;
  }

  @media ($min-xl) {
    min-height: 100vh;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 55vh;
    background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($white, 0.89) 69.2%);

    @media ($min-md) {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    z-index: 2;

    @media ($min-md) {
      @include container-desktop();
      align-items: flex-start;
      text-align: start;
      padding-left: 26px;
      width: 100%;
      margin-top: $header-desktop;
    }
  }

  &__title,
  &__description {
    max-width: 285px;

    @media ($min-md) {
      max-width: 425px;
    }
  }
  
  &__title {
    font-size: rem(45);
    margin: 0 0 10px;

    @media ($min-md) {
      margin-bottom: 8px;
    }
  }

  &__description {
    font-size: rem(14);
    line-height: 140%;
    letter-spacing: -0.05em;
    margin: 0 0 12px;

    @media ($min-md) {
      max-width: 250px;
      margin-bottom: 16px;
    }
  }

  &__button {
    margin: 0 0 12px;

    @media ($min-md) {
      margin-bottom: 16px;
    }
  }

  &__emphasis {
    display: none;
    z-index: 1;
    
    @media ($min-md) {
      margin: 0;
      display: block;
      font-weight: 700;
      font-size: 20.5vw;
      line-height: 100%;
      text-transform: uppercase;
      color: $grey-15;
    }

    @media ($min-hd) {
      font-size: rem(290);
    }
  }

  &__background {
    position: absolute;
    height: 100%;
    min-width: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    object-fit: cover;

    @media ($min-xl) {
      object-position: top;
    }
  }
}

.ser-bg {
  background-color: $darkgrey;
  text-align: center;
  padding: 30px;
  color: $lightgrey-50;

  @media ($min-lg) {
    padding: 50px;
    color: $white;
  }

  &__title {
    margin: 0;
    line-height: 120%;
    margin-bottom: 38px;

    @media ($min-lg) {
      font-weight: 300;
      font-size: rem(50);
      margin-bottom: 56px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;

    @media ($min-lg) {
      @include container-desktop;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 428px;
    align-items: center;
    gap: 18px;
    color: $lightgrey-50;

    @media ($min-lg) {
      align-items: flex-start;
      text-align: start;
      gap: 10px;
      flex: 1 1 0;
      color: $white;
    }

    @include on-event(true) {
      text-decoration: none;
    }

    &:hover .ser-bg__content-link {
      text-decoration: underline;
    }
    &:focus-visible .ser-bg__content-link {
      background-color: $darkgrey;
      outline: $focus;
      text-decoration: none;
    }
    &:active .ser-bg__content-link {
      outline: none;
      color: $grey-50;
      text-decoration: none;

      img {
        filter: brightness(0) invert(0.8);
      }
    }
  }

  &__content-title {
    margin: 0;

    @media ($min-xl) {
      margin-bottom: 10px;
      font-size: rem(50);
    }
  }

  &__content-description {
    margin: 0;
    font-size: rem(18);
    line-height: 120%;

    @media ($min-xl) {
      font-size: rem(20);
    }
  }

  &__content-link {
    display: flex;
    gap: 10px;
    font-weight: 700;
    font-size: rem(14);
    line-height: 120%;
    color: $lightgrey-50;

    @media ($min-lg) {
      color: $white;
    }

    @media ($min-xl) {
      font-size: rem(16);
    }

    img {
      filter: brightness(0) invert(1);
    }
  }
}

.history {
  background-color: $darkgrey;
  text-align: center;
  padding: 25px 0;
  position: relative;

  @media ($min-lg) {
    padding: 50px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 56px;
    margin: 0 20px;

    @media ($min-lg) {
      @include container-desktop;
      align-items: flex-start;
      gap: 20px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 428px;
    gap: 18px;
    padding: 0;
    color: $lightgrey-50;
    z-index: 1;

    @media ($min-lg) {
      align-items: flex-start;
      text-align: start;
      gap: 10px;
      color: $white;
      max-width: 280px;
    }

    @include on-event(true) {
      text-decoration: none;
    }

    &:hover .history__content-link {
      text-decoration: underline;
    }
    &:focus-visible .history__content-link {
      background-color: $darkgrey;
      outline: $focus;
      text-decoration: none;
    }
    &:active .history__content-link {
      outline: none;
      color: $grey-50;
      text-decoration: none;

      img {
        filter: brightness(0) invert(0.8);
      }
    }
  }

  &__content-title {
    margin: 0;

    @media ($min-xl) {
      margin-bottom: 10px;
      font-size: rem(50);
    }
  }

  &__content-description {
    margin: 0;
    font-size: rem(18);
    line-height: 120%;

    @media ($min-xl) {
      font-size: rem(20);
    }
  }

  &__content-link {
    display: flex;
    gap: 10px;
    font-weight: 700;
    font-size: rem(14);
    line-height: 120%;
    color: $lightgrey-50;

    @media ($min-lg) {
      color: $white;
    }

    @media ($min-xl) {
      font-size: rem(16);
    }

    img {
      filter: brightness(0) invert(1);
    }
  }

  &__emphasis {
    -webkit-text-stroke: 1px rgba($lightgrey-50, 0.6);
    font-size: 19vw;
    line-height: 100%;
    margin: 160px 0 0;
    text-transform: uppercase;
    font-weight: 700;
    color: transparent;
    align-self: center;
    z-index: 1;

    @media ($min-hd) {
      font-size: 265px;
    }
  }

  &__background {
    position: absolute;
    height: 100%;
    min-width: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    object-fit: cover;
    object-position: bottom center;

    @media($min-md) {
      object-position: bottom right;
    }
  }
}

.more-services {
  background-color: $white;
  text-align: center;
  padding: 44px 20px 64px;

  @media ($min-lg) {
    padding: 112px 50px 192px;
  }

  &__title {
    font-weight: 300;
    line-height: 120%;
    margin: 0 0 60px;

    @media ($min-lg) {
      font-weight: 300;
      font-size: rem(50);
      margin-bottom: 100px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;

    @media ($min-lg) {
      @include container-desktop;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 428px;
    align-items: center;
    gap: 18px;
    color: $black;

    @media ($min-lg) {
      align-items: flex-start;
      text-align: start;
      gap: 10px;
      flex: 1 1 0;
    }

    @include on-event(true) {
      text-decoration: none;
    }

    &:hover {
      & .more-services__content-hr {
        background-color: $primary;
      }

      & .more-services__content-link {
        text-decoration: underline;
      }
    }
    &:focus-visible .more-services__content-link {
      background-color: $white;
      outline: $focus;
      text-decoration: none;
    }
    &:active .more-services__content-link {
      outline: none;
      color: $primary-50;
      text-decoration: none;
  
      img {
        filter: brightness(1.9);
      }
    }
  }

  &__content-hr {
    width: 70px;
    height: 6px;
    background: $grey-50;
    border-radius: 3px;
    border: 0;
    transition: background-color 0.3s;

    @media ($min-lg) {
      margin-left: 0;
    }
  }

  &__content-title {
    margin: 0;

    @media ($min-xl) {
      margin-bottom: 10px;
      font-size: rem(50);
    }
  }

  &__content-description {
    margin: 0;
    line-height: 120%;

    @media ($min-xl) {
      font-size: rem(20);
    }
  }

  &__content-link {
    color: $primary;
    display: flex;
    gap: 10px;
    font-weight: 700;
    font-size: rem(14);
    line-height: 120%;

    @media ($min-xl) {
      font-size: rem(16);
    }
  }
}

.digital {
  position: relative;
  background-color: $grey-60;

  &__container {
    padding: 82px 20px 0px;
    overflow: hidden;

    @media ($min-lg) {
      @include container-desktop();
      padding: 136px 50px 104px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 12px;
    position: relative;
    z-index: 2;

    @media ($min-lg) {
      gap: 8px;
      text-align: start;
      align-items: flex-start;
      max-width: 678px;
    }
  }

  &__title {
    margin: 0;
    font-size: rem(45);

    @media ($min-lg) {
      font-size: rem(50);
    }
  }

  &__description {
    margin: 0;
    font-size: rem(20);
    line-height: 140%;
    letter-spacing: -0.05em;
  }

  &__button {
    margin-top: 8px;

    @media ($min-lg) {
      margin-top: 24px;
    }
  }

  &__emphasis {
    -webkit-text-stroke: 1px $white;
    font-size: 310px;
    line-height: 70%;
    margin: 0;
    text-transform: uppercase;
    font-weight: 700;
    color: transparent;
    position: relative;
    transform: translate(-100%, -35%);
    z-index: 1;

    @media ($min-sm) {
      transform: translate(-35%, -35%);
    }

    @media ($min-lg) {
      transform: none;
      font-size: 22vw;
      line-height: 100%;
      align-self: center;
    }

    @media ($min-hd) {
      margin-left: -26px;
      font-size: 310px;
    }
  }

  &__background {
    position: absolute;
    height: 100%;
    min-width: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    object-fit: cover;
    object-position: center right;

    @media ($min-md) {
      object-position: center;
    }    
  }
}