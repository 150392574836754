.secondary-menu {
  background-color: $darksilver;
  padding: 16px 8px;
  z-index: $hyperlink-menu-index;

  @media ($min-lg) {
    padding: 22px;
  }
  
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;

    @media ($min-lg) {
      gap: 12px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 148px;
    height: 32px;
    background-color: $white;
    border-radius: 20px;
    padding: 8px 12px;

    @media ($min-md) {
      width: 234px;
    }

    @media ($min-lg) {
      width: 320px;
    }

    &-text {
      font-weight: 700;
      font-size: rem(10);
      line-height: 1;
      text-transform: uppercase;
      color: $black-75;
      text-align: center;

      /*! autoprefixer: off */
      -webkit-box-orient: vertical; 
      /* autoprefixer: on */
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;

      @media ($min-lg) {
        font-size: rem(12);
      }
    }

    &--active {
      background-color: $grey-60;
    }
  }
}