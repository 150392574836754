.erro {
	background-color: $lightgrey-50;
	padding-block: rem(40);
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;

	@media ($min-xl) {
		padding-block: rem(80);
	}

	&__container {
		max-width: 900px;
		margin-inline: auto;
		padding-inline: rem(20);
		text-align: center;
	}

	&__description {
		margin-block: rem(20) rem(40);
	}
}