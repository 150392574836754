.how-to-hire {
	padding: 40px 20px 0;
	background-color: $darkgrey;
	color: $white;

	@media ($min-lg) {
		max-width: 900px;
		margin-inline: auto;
		padding-block-end: 80px;
		padding-inline: 0;
		background-color: $white;
		color: $black;
	}

	&__container {
		@media ($min-xl) {
			@include container-desktop();
		}
	}

	&__title {
		text-align: center;
	}

	&__description {
		margin-block: 30px;

		@media ($min-lg) {
			margin-block: 40px;
		}
	}
}