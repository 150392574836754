$button-size-variables: ('medium', 'big');

$button-theme-variables: (
  'primary': (
    'background': $primary,
    'border': $primary,
    'color': $white,
    'hover-background': $white,
    'hover-border': $white,
    'hover-color': $primary,
    'active-background': $primary-50,
    'active-border': $primary-50,
    'active-color': $white
  ),
  'light': (
    'background': $white,
    'border': $white,
    'color': $primary,
    'hover-background': $primary,
    'hover-border': $primary,
    'hover-color': $white,
    'active-background': $primary-50,
    'active-border': $primary-50,
    'active-color': $white
  ),
  'blue-grey': (
    'background': $blue-grey,
    'border': $blue-grey,
    'color': $white,
    'hover-background': $darkblue,
    'hover-border': $darkblue,
    'hover-color': $white,
    'active-background': $darkblue,
    'active-border': $darkblue,
    'active-color': $white
  ),
  'outline-primary': (
    'background': transparent,
    'border': $primary,
    'color': $primary,
    'hover-background': $white,
    'hover-border': $white,
    'hover-color': $primary,
    'active-background': $white,
    'active-border': $primary,
    'active-color': $primary
  ),
  'outline-light': (
    'background': transparent,
    'border': $white,
    'color': $white,
    'hover-background': rgba($black-75, 0.6),
    'hover-border': $white,
    'hover-color': $white,
    'active-background': $black-75,
    'active-border': $white,
    'active-color': $white
  ),
  'outline-dark': (
    'background': transparent,
    'border': $darkgrey,
    'color': $black,
    'hover-background': $white,
    'hover-border': $darkgrey,
    'hover-color': $black,
    'active-background': $lightgrey,
    'active-border': $darkgrey,
    'active-color': $black
  ),
  'outline-blue-grey': (
    'background': transparent,
    'border': $blue-grey,
    'color': $blue-grey,
    'hover-background': $blue-grey,
    'hover-border': $blue-grey,
    'hover-color': $white,
    'active-background': $lightgrey,
    'active-border': $blue-grey,
    'active-color': $blue-grey
  )
);

@mixin button-theme(
  $background,
  $border,
  $color,
  $hover-background,
  $hover-border,
  $hover-color,
  $active-background,
  $active-border,
  $active-color
){
  color: $color;
  background: $background;
  border: 1px solid $border;
  
  &:hover {
    color: $hover-color;
    background-color: $hover-background;
    border-color: $hover-border;
  }
  
  &:focus-visible,
  &:active {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;
  }
  
  &:focus-visible {
    outline: $focus;
  }
};

@mixin button-size ($size: medium) {
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s;

  @include on-event(true) {
    text-decoration: none;
  }

  // SIZE
  @if $size == big {
    font-weight: 700;
    font-size: rem(16);
    line-height: rem(28);
    padding: 16px 20px;
    border-radius: 30px;
    min-width: 184px;
  } @else {
    font-weight: 700;
    font-size: rem(13);
    line-height: rem(20);
    padding: 10px 16px;
    border-radius: 20px;
    min-width: 135px;
  }
}

@for $i from 1 through length($button-size-variables) {
  $actualValue: nth($button-size-variables, $i);

  @if ($actualValue == 'medium') {
    .btn {
      @include button-size();
    }
  } @else {
    .btn-#{$actualValue} {
      @include button-size($actualValue);
    }
  }
}

@each $theme, $themesMap in $button-theme-variables {
  .btn-#{$theme} {
    @include button-theme(
      map-get($themesMap, 'background'),
      map-get($themesMap, 'border'),
      map-get($themesMap, 'color'),
      map-get($themesMap, 'hover-background'),
      map-get($themesMap, 'hover-border'),
      map-get($themesMap, 'hover-color'),
      map-get($themesMap, 'active-background'),
      map-get($themesMap, 'active-border'),
      map-get($themesMap, 'active-color')
    );
  }
}

.search-icon {
  display: none;
  transition: all 0.3s;
  background-color: transparent;
  width: 40px;
  height: 40px;
  padding: 10px;
  border: 0;

  &:hover,
  &:focus-visible,
  &:active {
    background-color: $white;
    border-radius: 50%;
  }

  &:focus-visible {
    outline: $focus;
  }

  &:active img {
    filter: brightness(1.5);
  }

  img {
    width: 19px;
  }

  @media ($min-xl) {
    display: inline-block;
  }
}