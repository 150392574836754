// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// BREAKPOINTS
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$min-sm: 'min-width: #{$sm}';
$min-md: 'min-width: #{$md}';
$min-lg: 'min-width: #{$lg}';
$min-xl: 'min-width: #{$xl}';
$min-hd: 'min-width: #{$xxl}';
$max-xs: 'max-width: #{$sm - 0.2px}';
$max-sm: 'max-width: #{$md - 0.2px}';
$max-md: 'max-width: #{$lg - 0.2px}';
$max-lg: 'max-width: #{$xl - 0.2px}';
$max-xl: 'max-width: #{$xxl - 0.2px}';
$max-height-md: 'max-height: #{$md - 0.2px}'; 

$max-width: 1336px;

// COLORS
$primary: #5E010D;
$primary-75: #770111;
$primary-50: #A50116;
$primary-40: #D8001C;
$white: #FFF;
$black: #0D0D0D;
$black-85: #262626;
$black-75: #2E3032;
$black-50: #3A3A3A;


// AUXILIAR COLORS
$darkgrey: #81888C;
$grey-60: #B3BCC0;
$grey-50: #D3D8DA;
$grey-20: #D1D1D1;
$grey-15: #d5d5d5;
$grey-10: #d9d9d9;
$lightgrey: #E5E5E5;
$lightgrey-95: #E8E8E8; 
$lightgrey-70: #ECECEC;
$lightgrey-65: #E9EEF0;
$lightgrey-50: #F4F4F4; 
$blue-grey: #688291;
$darksilver: #565C60;
$lightblue: #F5FCFE;
$darkblue: #293D47;

// FOCUS
$focus: 4px solid #FFDDDD;

// SHADOWS
$shadow-drop: 0px 0px 20px rgba(0, 0, 0, 0.25);
$shadow-focus: 0px 0px 0px 4px #FFDDDD;

//BORDER RADIUS
$border-radius: 5px;
$border-radius-sm: 3px;

// FONTS
$primary-font: 'Gotham', sans-serif;

// HEADER SIZE
$header-mobile: 55.55px;
$header-tablet: 71.60px;
$header-desktop: 91px;

// z-index
$hyperlink-menu-index: 20;
$header-index: 30;
$modal-index: 40;


/// Container's maximum width
/// @type Length
$max-width: 1180px !default;
