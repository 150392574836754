.services-table {
  background-color: $lightgrey-50;
  padding: rem(8) rem(2);

  @media ($min-lg) {
    padding: rem(12) rem(2);
  }

  &:last-of-type {
    padding-bottom: rem(60);

    @media ($min-lg) {
      padding-bottom: rem(96);
    }
  }

  &__container {
    @include container-desktop();
    width: 100%;
  }

  &__section-title {
    margin-block: rem(26) rem(18);
    font-weight: 400;
    font-size: rem(25);
    line-height: 1.3;
    color: $black-85;
    text-align: center;

    @media ($min-lg) {
      margin-block: rem(64) rem(42);
      font-size: rem(25);
      line-height: 1.6;
    }

    &:empty {
      margin: 0;
    }
  }
  
  &__table {
    width: 100%;
    border-spacing: 2px;
  }

  &__header th {
    font-weight: 700;
    font-size: rem(13);
    line-height: 1;
    color: $primary;
    text-align: start;
    padding: rem(14) rem(12);

    @media ($min-lg) {
      padding: rem(8) rem(18);
    }
  }


  &__row {
    background-color: $white;

    &:nth-of-type(even) {
      background-color: $lightblue;
    }

    // Adding rounded border
    @media ($min-lg) {
      &:first-of-type td:first-child {
        border-radius: 5px 0 0 0;
      }

      &:first-of-type td:last-child {
        border-radius: 0 5px 0 0;
      }

      &:last-of-type td:first-child {
        border-radius: 0 0 0 5px;
      }

      &:last-of-type td:last-child {
        border-radius: 0 0 5px 0;
      }
    }

    & td {
      padding: rem(14) rem(12);
      font-size: rem(12);
      line-height: 1.6;
      color: $black-75;

      @media ($min-lg) {
        padding: rem(8) rem(18);
        font-size: rem(16);
      }
    }
  }

  &__title {
    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      min-height: rem(40);

      @media ($min-lg) {
        gap: 5px;
      }
    }

    & div {
      line-height: 0;
    }

    & strong {
      display: block;
      font-size: rem(12);
      line-height: 1;
      color: $black-75;

      @media ($min-lg) {
        font-size: rem(16);
      }
    }

    & p {
      display: block;
      font-size: rem(12);
      line-height: 1;

      @media ($min-md) {
        display: inline-block;

        &:first-of-type:after {
          content: '\2022';
          color: $darkgrey;
          font-size: rem(15);
          margin-left: 6px;
        }
      }

      @media ($min-lg) {
        font-size: rem(14);
      }

      & + p {
        margin-top: rem(8);

        @media ($min-md) {
          margin-top: 0;
        }
      }
    }
  }

  &__subtitle {
    color: $darkgrey;
  }

  &__price strong {
    color: $blue-grey;
  }
}