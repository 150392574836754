.reports {
  background-color: $lightgrey-50;
  padding: rem(30) rem(20) rem(60);

  @media ($min-lg) {
    padding: rem(80) rem(20) rem(108);
  }

  &__container {
    @include container-desktop();
    max-width: 900px;

    &-maxwidth-1336 {
      max-width: $max-width;
    }
  }

  &__title {
    font-size: rem(35);
    line-height: 1;
    color: $black;
    margin-bottom: rem(18);
    text-align: center;

    @media ($min-lg) {
      font-size: rem(50);
      margin-bottom: rem(34);
      color: $black-85;
    }

    &:empty {
      margin: 0;
    }
  }

  &__description {
    font-size: rem(16);
    line-height: 1.6;
    color: $black;
    margin-bottom: rem(16);

    @media ($min-lg) {
      font-size: rem(18);
      margin-bottom: rem(40);
    }

    &:empty {
      margin: 0;
    }

    &-maxwidth-900 {
      max-width: 900px;
      margin-inline: auto;
    }
  }

  &__select {
    appearance: none;
    width: 100%;
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNyA3TDEzIDEiIHN0cm9rZT0iIzVFMDEwRCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-color: $white;

    border: 1px solid $grey-50;
    border-radius: 20px;
    padding: 12px 36px 12px 20px;
    margin-bottom: rem(30);

    font-size: rem(10);
    line-height: 1.3;
    text-transform: uppercase;
    color: $black-85;

    @media ($min-lg) {
      font-size: rem(13);
    }

    @media ($min-xl) {
      width: auto;
      margin-bottom: rem(40);
      padding: 12px 62px 12px 20px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: rem(15);
  }

  &--dark {
    background-color: $darkgrey;

    & .reports__title,
    & .reports__description {
      color: $white;
    }
  }
}