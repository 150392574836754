.faq {
  background-color: $lightgrey-50;
  padding: rem(30) rem(20) rem(30);
  
  @media ($min-lg) {
    padding: rem(80) rem(20) rem(80);
  }

  &__wrapper {
    @include container-desktop();
  }

  &__title {
    font-weight: 700;
    font-size: rem(35);
    line-height: 1;
    text-align: center;
    color: $black;
    margin-bottom: rem(20);

    @media ($min-lg) {
      font-size: rem(50);
      color: $black-85;
      margin-bottom: rem(44);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: rem(15);
  }
}