.mission-vision {
	background-color: $darkgrey;
	position: relative;

	@media ($min-lg) {
		padding: rem(80) 0;
	}

	&:before {
		@media ($min-lg) {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			height: 100%;
			background-color: $darksilver;
		}
	}

	&__container {
		position: relative;

		@media ($min-lg) {
			@include container-desktop();
		}
	}

	&__title {
		background-color: $white;
		padding-block: rem(30); 
		text-align: center;

		@media ($min-lg) {
			background-color: transparent;
			color: $white;
		}
	}

	&__items {
		display: grid;
		grid-template-columns: 1fr;

		@media ($min-lg) {
			grid-template-columns: 1fr 1fr;
		}
	}

	&__item {
		color: $white;
		padding: rem(40) rem(20);

		@media ($min-lg) {
			padding-inline: rem(80);
		}

		@media ($min-xl) {
			padding-inline: rem(120);
		}

		&:first-of-type {
			background-color: $darksilver;

			@media ($min-lg) {
				background-color: transparent;
			}
		}

		&-title {
			text-align: center;
			position: relative;
			padding-block-end: rem(12);
			margin-block-end: rem(18);

			&:before {
				content: "";
				width: 60px;
				height: 5px;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%);
				background-color: $grey-50;
				border-radius: $border-radius-sm;
			}
		}
	}

	&.mission-vision--cards {
		&:before {
			@media ($min-lg) {
				content: none;
			}
		}

		& .mission-vision__item {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: rem(18);

			&-title {
				margin-block-end: 0;
			}
		}

		& .mission-vision__image {
			width: 100%;
			max-width: rem(428);
		}

		& .mission-vision__title {
			background-color: transparent;
			color: $white;
		}

		& .mission-vision__item:first-of-type {
			background-color: transparent;
		}
	}
}