.card {
	background-color: $lightgrey-50;
	padding: rem(20);
	border-radius: $border-radius-sm;

	@media ($min-lg) {
		padding: rem(24);
	}

	&-title {
		position: relative;
		padding-top: rem(32);
		margin-block-end: rem(12);
		font-size: rem(35);

		&:before {
			content: "";
			width: 60px;
			height: 6px;
			background-color: $primary-75;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: $border-radius-sm;

			@media ($min-xl) {
				width: 90px;
			}
		}
	}
}