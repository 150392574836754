// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: $primary-font;
  color: $black;
  font-size: rem(18);
  line-height: 160%;
}

#main {
  flex: 1;
  padding-top: $header-mobile;
  display: flex;
  flex-direction: column;
  position: relative;

  @media ($min-md) {
    padding-top: $header-tablet;
  }

  @media ($min-xl) {
    padding-top: 0;

    & > section:first-of-type:not(.hero, .home-hero),
    & > div:first-of-type:not(.hero, .home-hero) {
      margin-top: $header-desktop;
    }
  }
}

input, button, select, textarea, optgroup, option {
  font-family: $primary-font;
}

/**
 * Basic styles for links
 */
a {
  color: $primary;
  text-decoration: none;

  @include on-event {
    text-decoration: underline;
  }
}

button {
  cursor: pointer;
}