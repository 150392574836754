.milestones {
	padding: rem(40) rem(20) rem(20);

	@media ($min-lg) {
		padding-block: rem(80) rem(40);
	}

	&__container {	
		@media ($min-lg) {
			@include container-desktop();
		}
	}

	&__title {
		text-align: center;
		margin-block-end: 40px;
	}

	&__items {
		display: flex;
		flex-direction: column;
		margin: rem(-10);
	
		@media ($min-md) {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	&__item {
		@media ($min-md) {
			flex-basis: 50%;
			padding: rem(10);
		}
		@media ($min-lg) {
			flex-basis: 33.3%;
		}

		&-title {
			text-align: center;
			position: relative;
			margin-block: rem(18);
			padding-block-end: rem(12);

			&:after {
				content: "";
				width: 60px;
				height: 5px;
				border: none;
				background-color: $darksilver;
				margin: 0 auto;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				border-radius: $border-radius-sm;
			}
		}
		
		&-img {
			display: flex;

			&>img {
				object-fit: cover;
				width: 100%;
			}
		}
	}
}
