.introduction {
	padding: 28px 20px 38px;
	background-color: $white;

	@media ($min-xl) {
		padding: 80px 30px;
	}

	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 32px;

		& picture {
			flex: 1 0 auto;
		}

		@media ($min-xl) {
			@include container-desktop();
			flex-direction: row;
			gap: 56px;
		}
	}

	&__image {
		width: 100%;
		max-width: 360px;
		
		@media ($min-md) {
			max-width: 490px;
		}
		
		img {
			max-width: 100%;
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		gap: 16px;
		max-width: 600px;

		@media ($min-xl) {
			max-width: none;
			gap: 32px;
		}
	}

	&__title {
		font-weight: 700;
		font-size: rem(35);
		line-height: 100%;
		color: $black;
		text-align: center;
		margin: 0;

		@media ($min-xl) {
			font-size: rem(50);
			text-align: left;
		}
	}

	&__paragraph {
		text-align: start;
		font-weight: 400;
		font-size: rem(16);
		line-height: 160%;
		color: $black;

		@media ($min-xl) {
			font-size: rem(18);
		}
	}

	&.introduction--dark {
		background-color: $black-75;

		.introduction__title,
		.introduction__paragraph {
			color: $white;
		}
	}
}