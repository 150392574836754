.document {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  background-color: $white;
  border: 1px solid $grey-50;

  &__image-container {
    display: flex;
    align-items: center;
    background-color: $grey-50;
    line-height: 0;
    padding: rem(42) rem(15);

    @media ($min-lg) {
      padding: rem(15);
    }
  }

  &__image {
    max-width: rem(40);
    max-height: rem(40);
  }

  &__wrapper {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    gap: 12px;
    padding: 12px 8px;

    @media ($min-lg) {
      display: flex;
      gap: 32px;
      padding-inline: 30px 14px;
    }
  }

  &__title {
    font-size: rem(13);
    line-height: 1.1;
    color: $black-85;

    /*! autoprefixer: off */
    -webkit-box-orient: vertical; 
    /* autoprefixer: on */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;

    grid-column: 1 / 3;

    @media ($min-md) {
      font-size: rem(18);
    }

    @media ($min-lg) {
      flex: 1;
      -webkit-line-clamp: 2;
    }
  }

  &__file-info {
    font-size: rem(10);
    line-height: 1.5;
    color: $black;

    @media ($min-lg) {
      font-size: rem(12);
    }
  }

  &__button {
    min-width: initial;
    width: 100%;
    max-width: 250px;
    justify-self: end;

    @media ($min-lg) {
      min-width: rem(100);
      width: initial;
      max-width: initial;
    }
  }
}