// Adicionando ordem nos blocos para mudar posição do terceiro bloco no desktop.
@for $order from 1 to 7 {
  .mosaic-services__content:nth-child(#{$order}) {
    order: $order;
  }
}

.mosaic-services {
  @media ($min-md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 56px;
  }

  &__content {
    background-color: $lightgrey-50;
    padding: rem(16) rem(20);
    min-height: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: rem(18);

    @media ($min-md) {
      min-height: 368px;
    }

    @media ($min-xl) {
      min-height: 512px;
      text-align: left;
      gap: rem(30);
    }

    &:first-child {
      @media ($min-md) {
        background: linear-gradient(306.53deg, $lightgrey-50 0%, rgba($white, 0) 74.44%);
      }
    }

    &:nth-child(even) {
      background-color: $white;
    }

    &:nth-child(3) {
      background-color: $darkgrey;

      @media ($min-md) {
        order: 5;
      }
    }
  }
  
  &__image {
    max-width: 100%;
    height: 240px;

    @media ($min-md) {
      height: 368px;
    }

    
    @media ($min-xl) {
      height: 512px;
    }

  }

  &__title {
    font-weight: 700;
    font-size: rem(35);
    line-height: 1;
    color: $black;
    max-width: rem(280);

    @media ($min-xl) {
      font-size: rem(50);
      line-height: 1;
      width: 100%;
      max-width: rem(408);
    }
  }

  &__description {
    font-size: rem(18);
    line-height: 1.2;
    max-width: rem(280);

    @media ($min-xl) {
      font-size: rem(20);
      width: 100%;
      max-width: rem(408);
    }
  }

  &__content-link {
    color: $primary;
    display: flex;
    gap: 10px;
    font-weight: 700;
    font-size: rem(16);
    line-height: 120%;

    @media ($min-xl) {
      width: 100%;
      max-width: rem(408);
    }

    &:focus-visible {
      background-color: $white;
      outline: $focus;
      text-decoration: none;
    }
    &:active {
      outline: none;
      color: $primary-50;
      text-decoration: none;
  
      img {
        filter: brightness(1.9);
      }
    }
  }
}