.simple-introduction {
	padding: 28px 20px;

	@media ($min-xl) {
		padding: 80px 20px 32px;

		&+.simple-introduction {
			padding-top: 48px;
		}
	}

	&__container {
		@media ($min-md) {
			@include container-desktop();
		}
	}

	&__title {
		text-align: center;
		margin-block-end: 40px;

		@media ($min-md) {
			max-width: 900px;
			margin-inline: auto;
		}

		&:empty {
      margin: 0;
    }
	}

	&__description {
		font-size: rem(16);
		line-height: 1.6;
		color: $black-85;

		& + .simple-introduction__description {
			margin-top: rem(16);
		}

		@media ($min-md) {
			font-size: rem(18);
			max-width: 900px;
			margin-inline: auto;
		}

		&:empty {
      margin: 0;
    }
	}

	&__image {
		margin-top: rem(50);
		width: 100%;
	}

	&--lightgrey {
		background-color: $lightgrey-50;
	}
}