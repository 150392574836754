.about-service {
	padding: 40px 20px 0;

	@media ($min-lg) {
		padding: 120px 20px 70px;
	}

	&__container {
		display: flex;
		flex-direction: column;
		gap: 32px;

		@media ($min-lg) {
			@include container-desktop();
			max-width: 900px;
			gap: 46px;
		}
	}

	hr {
		width: 60px;
		height: 5px;
		background-color: $darksilver;
		border: none;
		border-radius: $border-radius-sm;
		margin: 0 0 5px;

		@media ($min-lg) {
			margin: 0 0 10px;
		}
	}

	&__content {
		text-align: center;

		@media ($min-lg) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&-title {
			font-weight: 700;
			font-size: rem(35);
			line-height: 100%;
			text-align: center;
			margin: 0 0 16px;

			@media ($min-lg) {
				max-width: 428px;
				font-size: rem(50);
				line-height: 120%;
				text-align: start;
				z-index: 1;
			}
		}

		&-image {
			width: 100%;
			max-width: 360px;

			@media ($min-lg) {
				max-width: 655px;
				margin-left: -25%;
			}
		}
	}

	&__how-it-works {
		display: flex;
		flex-direction: column;
		gap: 15px;

		&-title {
			margin: 0;
			font-weight: 400;
			font-size: rem(22);
			line-height: 160%;

			@media ($min-lg) {
				font-size: rem(25);
				margin: -22px 0 0;
			}
		}

		&-description {
			margin: 0;
			font-weight: 400;
			font-size: rem(16);
			line-height: 160%;

			@media ($min-lg) {
				font-size: rem(18);
			}
		}
	}

	&__benefits {
		display: flex;
		flex-direction: column;
		gap: 15px;

		&-title {
			margin: 0;
			font-weight: 400;
			font-size: rem(22);
			line-height: 160%;

			@media ($min-lg) {
				font-size: rem(25);
			}
		}

		&-list {
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
			flex-direction: column;
			gap: 30px;

			&-item {
				display: flex;
				flex-direction: column;
				gap: 18px;

				@media ($min-lg) {
					flex-direction: row;
					align-items: center;
				}

				&-image {
					width: 105px;
					height: 105px;
					background-color: $lightgrey-50;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					border-radius: $border-radius;
					flex-shrink: 0;
				}

				&-description {
					font-size: rem(16);
					margin-block: 0;

					@media ($min-lg) {
						font-size: rem(18);
						line-height: 160%;
					}
				}
			}
		}
	}

	&__contact {
		background-color: $lightgrey-50;
		margin: 30px -20px 0;
		padding: 30px;
		text-align: center;
		position: relative;

		@media ($min-lg) {
			margin: 10px 0 0;
			padding: 0;
			display: flex;
			justify-content: space-around;
			align-items: center;
			background-image: url('../img/bg-contact.png');
			background-position: bottom right;
    	background-repeat: no-repeat;
		}

		&-content {
			@media ($min-lg) {
				max-width: 488px;
			}

			&-description {
				font-size: rem(30);
				line-height: rem(36);
				font-weight: 300;
				margin: 0;
			}

			&-button {
				margin-block-start: 20px;

				@media ($min-lg) {
					margin-block-start: 25px;
				}
			}
		}

		&-img {
			display: none;

			@media ($min-lg) {
				display: block;
				margin: -20px 0;
			}
		}
	}

	&--dark {
		background-color: $darkgrey;
		color: $white;

		& .about-service__contact {
			background-color: rgba($black-75, .1);
		}

		& .about-service__benefits-list-item-image {
			background-color: $darksilver;

			& img {
				filter: brightness(1.2);
			}
		}
	}
}