.venha-ser-bg {
  @include container-desktop();
  width: 100%;
  display: flex;
  flex: 1;
  margin-bottom: rem(80);
  padding-inline: rem(20);

  @media ($min-hd) {
    padding-inline: 0;
  }

  &__title {
    margin-block: rem(32);
  }

  &__description {
    font-size: rem(18);
    line-height: 1.6;
    color: $black-75;
  }

  &__background {
    position: absolute;
    height: 100%;
    min-width: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
  }

  & .default-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
    max-width: rem(550);
    margin-inline: 0 auto;
  }

  & .default-form__legend {
    margin-bottom: rem(40);
  }
}