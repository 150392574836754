.topics {
	padding: 40px 20px 20px;
	
	@media ($min-lg) {
		max-width: 900px;
		margin-inline: auto;
		padding: 80px 0 40px;
	}

	&__container {
		@media ($min-xl) {
			@include container-desktop();
		}
	}

	&__title {
		text-align: center;
	}

	&__list {
		display: flex;
		flex-direction: column;
		gap: 36px;
		margin-block-start: 40px;
	
		&-item {
			display: flex;
			align-items: center;
			flex-direction: column;
			align-items: flex-start;
			gap: 16px;
	
			@media ($min-md) {
				flex-direction: row;
				align-items: center;
			}
	
			&-icon {
				flex: 0 0 105px;
				width: 105px;
				height: 105px;
				border-radius: $border-radius-sm;
				background-color: $lightgrey-50;
				display: inline-flex;
				align-items: center;
				justify-content: center;
	
				&.icon--dark {
	
				}
			}
		}
	}
}