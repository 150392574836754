// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footer {
  width: 100%;
  padding: 12px 20px 6px;
  background-color: $white;

  @media ($min-xl) {
    padding: 32px 20px 28px;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin: 0 auto;

    @media ($min-xl) {
      @include container-desktop;
      flex-direction: row;
      gap: 36px;
    }
  }

  &__logo {
    line-height: 0;
    font-size: 0;

    img {
      width: 80px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    
    @media ($min-xl) {
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      gap: unset;
    }
  }

  &__hyperlinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 8px;
    font-size: rem(10);
    line-height: rem(17);
    white-space: nowrap;
    margin: 0;
    list-style: none;
    padding: 0;

    & li:not(:last-child):after {
      content: "|";
      margin-left: 16px;
    }

    @media ($min-md) {
      font-size: rem(13);
      gap: 0 16px;
    }
  }

  &__social {
    display: flex;
    gap: 26px;
    margin: 0;
    padding: 0;
    list-style: none;

    a {
      line-height: 0;
      font-size: 0;
    }

    img {
      width: 16px;
    }
  }

  &__copyright {
    margin: 0;
    font-size: rem(7);
    color: $black;

    @media ($min-md) {
      font-size: rem(11);
    }
  }
}