.search-results {
  padding: rem(40) rem(20) rem(60);
  background-color: $lightgrey-50;
  flex: 1;

  @media ($min-xl) {
    padding: rem(80) rem(20) rem(80);
  }

  &__container {
    @include container-desktop();

    max-width: 900px;
  }

  &__title {
    font-size: rem(35);
    line-height: 1;
    text-align: center;
    color: $black;
    margin-bottom: rem(16);

    @media ($min-xl) {
      font-size: rem(50);
      margin-bottom: rem(48);
    }
  }

  &__form {
    display: flex;
    align-items: center;
    background-color: $white;
    border-radius: 30px;
    padding: 8px 20px 8px 24px;
    margin-bottom: rem(40);

    @media ($min-xl) {
      padding: 10px 24px;
      margin-bottom: rem(46);
    }

    & input[type="text"] {
      width: 100%;
      font-weight: 400;
      font-size: rem(16);
      line-height: 1.6;
      color: $black-75;
      border: 0;

      &:focus,
      &:active {
        outline: none;
      }

      &:placeholder {
        color: $black-75;
      }
    }

    &-button {
      border: 0;
      background-color: transparent;
      width: 24px;
      height: 24px;
      padding: 4px 6px;
      transition: all 0.3s;
      border-radius: 50%;
      line-height: 0;

      @media ($min-xl) {
        width: 40px;
        height: 40px;
        padding: 10px;
      }

      &:hover img,
      &:focus-visible img,
      &:active img {
        filter: brightness(0) invert(1);
      }
      
      &:hover,
      &:focus-visible {
        background-color: $primary;
      }

      &:focus-visible {
        outline: $focus;
      }

      &:active {
        background-color: $primary-50;
      }

      img {
        width: 12px;
        filter: brightness(1.5);

        @media ($min-xl) {
          width: auto;
        }
      } 
    }
  }

  &__no-result p {
    font-size: rem(22);
    line-height: 1.6;
    text-align: center;

    @media ($min-lg) {
      font-size: rem(25);
    }
  }

  &__quantity-text {
    font-size: rem(16);
    line-height: 1.6;
    color: $black-75;
    margin-bottom: rem(6);
  }

  &__results-list {
    display: flex;
    flex-direction: column;
    gap: rem(40);

    border-top: 1px solid $grey-50;
    border-bottom: 1px solid $grey-50;
    padding-block: rem(30); 
    margin-bottom: rem(30);

    @media ($min-xl) {
      padding-block: rem(40);
    }
  }

  &__result {
    &-title {
      font-size: rem(22);
      line-height: 1.3;
      color: $primary;
      margin-bottom: rem(10);
      font-weight: 700;

      @media ($min-xl) {
        font-size: rem(25);
        line-height: 1.6;
      }
    }

    &-description {
      font-size: rem(16);
      line-height: 1.6;
      color: $black-85;

      & em {
        font-weight: 700;
        font-style: normal;
      }

      @media ($min-xl) {
        font-size: rem(18);
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 160px;
    margin: 0 auto;
    border-radius: 20px;
    overflow: hidden;

    background-color: $white;
  }

  &__actual-page {
    flex: 1;
    font-size: rem(13);
    line-height: 1.3;
    color: $black-85;
    text-align: center;
  }

  &__previous-page,
  &__next-page {
    padding: 14px 18px;
    line-height: 0;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: $primary;
      border-radius: 3px;

      .search-results__previous-arrow:before,
      .search-results__previous-arrow:after,
      .search-results__next-arrow:before,
      .search-results__next-arrow:after {
        background-color: $white;
      }
    }

    &--disabled {
      pointer-events: none;
      
      .search-results__previous-arrow:before,
      .search-results__previous-arrow:after,
      .search-results__next-arrow:before,
      .search-results__next-arrow:after {
        background-color: $darkgrey;
      }
    }
  }

  &__previous-arrow,
  &__next-arrow {
    width: 6px;
    height: 12px;
    display: inline-block;
    position: relative;
  
    &:before,
    &:after {
      left: 0;
      position: absolute;
      width: 8px;
      height: 2px;
      background-color: $primary;
      display: inline-block;
      transition: all .2s ease;
      border-radius: 25px;
    }

    &:after {
      content: "";
      top: 8px;
    }

    &:before {
      content: "";
      top: 3px;
    }
  }

  &__previous-arrow {
    &:after {
      transform: rotate(45deg);
    }
  
    &:before {
      transform: rotate(-45deg);
    }
  }

  &__next-arrow {
    &:after {
      transform: rotate(135deg);
    }
  
    &:before {
      transform: rotate(-135deg);
    }
  }
}